
window.setAdsRoles = (roles) => {
    window.adsRoles = roles;
}

window.adsNotification = () => {
    dataLayer.push({
        'event': 'ads_notification',
        'roles': JSON.stringify(window.adsRoles),
        'page': window.location.pathname,
    });

    if (typeof ibbAds !== 'undefined' && ibbAds && ibbAds.tag && ibbAds.tag.requestAndPlaceAds) {
        ibbAds.tag.requestAndPlaceAds('');
    }
};